<template>
  <v-app>
    <Navigation
      :menu_items="menu_items"
      :appTitle="appTitle"
      :logo="logo"
      :appDescription="appDescription"
    />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation.vue";
export default {
  name: "Dashboard",

  components: {
    Navigation,
  },

  data() {
    return {
      appTitle: "Celestine",
      appDescription: "Admin Panel",
      logo: { icon: "mdi-water-circle", color: "#E74C3C" },
      menu_items: [
        {
          head: true,
          icon: "mdi-database-edit-outline",
          text: "Databases",
          path: "/admin/databases",
        },

        {
          head: true,
          icon: "mdi-account-group-outline",
          text: "Users",
          path: "/admin/users",
        },

        {
          head: true,
          icon: "mdi-cash-multiple",
          text: "Subscriptions",
          path: "/admin/subscriptions",
        },

        {
          head: false,
          icon: "mdi-backup-restore",
          text: "Back to the site",
          path: "../",
          reload: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
